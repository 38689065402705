<template>
  <div v-if="checkUser">
    <b-row class="mt-5">
      <b-col cols="12">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="All" active>
              <rm-payout-all-table/>
            </b-tab>
            <b-tab title="Successful">
              <rm-payout-successful-tab />
            </b-tab>
            <b-tab title="Pending">
              <rm-payout-pending />
            </b-tab>
            <b-tab title="Failed">
              <rm-payout-failed />
            </b-tab>
            <b-tab title="Queued">
              <rm-payout-queued />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <p>You do not have access to this content</p>
  </div>
</template>

<script>
import RmPayoutAllTable from './components/RmPayoutAllTable.vue';
import RmPayoutSuccessfulTab from './components/RmPayoutSuccessfulTab.vue';
import RmPayoutQueued from './components/RmPayoutQueued.vue';
import RmPayoutFailed from './components/RmPayoutFailed.vue';
import RmPayoutPending from './components/RmPayoutPending.vue';
import { mapState } from 'vuex';
export default {
  components: {
    RmPayoutAllTable,
    RmPayoutFailed,
    RmPayoutQueued,
    RmPayoutPending,
    RmPayoutSuccessfulTab,
  },
  computed: ({
    ...mapState('auth', ['userObj']),
    userRoles() {
      return  this.userObj.role.scopes ?? []
    },
    checkUser(){
      return this.userRoles.includes('payout.read');
    }
  }),
  mounted(){
    // console.log(userRoles);
    // get user
    // if user.roles.scope does not include "payout.read" do not display page
  }
}
</script>

<style lang="scss" scoped></style>