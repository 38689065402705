<template>  
  <div class="bg-white p-4">
    <b-row no-gutters>
      <b-col cols="3" class="mr-2">
        <rm-input
          v-model="filterOptions.search"
          outlined
          placeholder="Search..."
        ></rm-input>
      </b-col>
      <b-col cols="3" class="d-flex">
        <rm-base-button
          class="mr-3"
          text="Search"
          @click="loadData"
        ></rm-base-button>
        <rm-base-button
          bgColor="danger"
          text="Clear Search"
          @click="clearFilter"
        ></rm-base-button>
      </b-col>
    </b-row>
    <b-row v-if="showFilter" class="mb-5">
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down
          v-model="filterOptions.marital_status"
          item-text="text"
          item-value="value"
          outlined
          label="Marital Status"
          :options="maritalStatusOptions"
        />
      </b-col>

      <b-col cols="2" class="ml-2">
        <rm-drop-down
          v-model="filterOptions.gender"
          outlined
          label="Gender"
          :options="genderOptions"
        />
      </b-col>

      <b-col cols="12" class="d-flex">
        <rm-base-button
          class="ml-3"
          text="Filter"
          bgColor="tertiary"
          textColor="white"
          @click="loadData"
        ></rm-base-button>
        <rm-base-button
          class="ml-3"
          text="Clear Filter"
          bgColor="tertiary--light"
          textColor="tertiary"
          @click="clearFilter"
        ></rm-base-button>
      </b-col>
    </b-row>

    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      hover
      :busy="isLoading"
      :items="Payout"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template #cell(name)="{ item }">
        <div
          class="text-primary pointer"
          @click="globalRouter('clients.profile', { id: item.user_id })"
        >
          {{ `${item.name}` }}
        </div>
      </template>
      <template #cell(charges)="{item}">
        <div>
          {{ item.charges || 0.00 }}
        </div>
      </template>
      <template #cell(status)="{ item }">
        <div class="d-flex justify-content-center">
          <span
            class="text-center payout-status"
            :class="`payout-status__${item.status}`"
          >
            {{ item.status }}
          </span>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="paging.totalRows"
      v-model="paging.currentPage"
      :total-rows="paging.totalRows"
      :per-page="paging.perPage"
      aria-controls="clientsTable"
      @change="changePage"
    />
  </div>
</template>

<script>
import formatDate from '@/mixins/formatDate.js';
import exportData from '@/mixins/exportData.js';

export default {
  data() {
    return {
      isLoading: false,
      Payout: [],
      selected: [],
      status: "queued",
      paging: {
        currentPage: 1,
        totalRows: null,
        perPage: null,
      },
      filterOptions: {
        search: null,
        marital_status: null,
        gender: null,
      },
      selectAll: false,
      showFilter: false,
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'name',
          label: 'NAME',
          class: 'text-center',
        },
        {
          key: 'amount',
          sortable: false,
          label: 'AMOUNT',
          class: 'text-center',
        },
        {
          key: 'charges',
          sortable: false,
          label: 'CHARGES',
          class: 'text-center',
        },
        {
          key: 'purpose',
          sortable: false,
          label: 'PURPOSE',
          class: 'text-center',
        },
        // {
        //   key: 'account_no',
        //   sortable: false,
        //   label: 'ACCOUNT NO',
        //   class: 'text-center',
        // },
        {
          key: 'status',
          sortable: false,
          label: 'STATUS',
          class: 'text-center',
        },
        {
          key: 'date',
          sortable: false,
          label: 'DATE',
        },
      ],
    };
  },
  computed: {},
  // components: {
  //   // RmCreateUser,
  // },
  created() {
    this.loadData();
  },
  mixins: [formatDate, exportData],
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    clearFilter() {
      this.filterOptions = {
        search: null,
        marital_status: null,
        gender: null,
      };

      this.loadData();
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      if (!this.showFilter) {
        this.filterOptions = {
          search: null,
          marital_status: null,
          gender: null,
        };
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          ...this.filterOptions,
          page: this.paging.currentPage,
          status: this.status,
        };
        await this.fetchPayouts(params);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPayouts(params) {
      const {paging, rows} = await this.$store.dispatch(
        'payouts/fetchPayouts',
        params
      );
      this.Payout = rows.map((item) => {
        return {
          id: item.id,
          name: item.stamp_user,
          user_id: item.user_id,
          email: item.email,
          entity: item.entity,
          purpose: item.purpose,
          phone_number: item.phone_number,
          amount: `₦${this.$formatCurrency(item?.total_amount / 100)}`,
          charges: `₦${this.$formatCurrency(item?.charges / 100)}`,
          status: item.status,
          date: this.formatDate(item.created_at),
        };
      });

      this.paging.currentPage = paging.currentPageNo;
      this.paging.totalRows = paging.total;
      this.paging.perPage = paging.perPage;
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.active {
  background-color: #fff5e9;
  color: #ec5800;
}
.inactive {
  background-color: #e8fbf4;
  color: #1d9067;
}
.payout-status {
  border-radius: 8px;
  padding: 4px 16px;
  max-width: 83px;
  color: $primary-color;
}
.payout-status__success {
  color: #00CD84;
  background: #E8FBF4;
}
.payout-status__pending {
  color: #F08000;
  background: #FFF5E9;
}
.payout-status__queued {
  color: #6A2A9D;
  background: #F0EAF5;
}
.payout-status__failed {
  color: #DF1B00;
  background: #FFEEEB;
}
</style>
